var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"manage-networkSolutions"}},[_c('v-container',{staticClass:"lighten-5"},[_c('v-container',{staticStyle:{"margin-bottom":"30px"}},[_c('dashboard-core-app-bar')],1),_c('v-breadcrumbs',{attrs:{"items":_vm.items,"divider":">"}}),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveProduct.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"type":"submit","color":"primary","dark":""}},[_vm._v(" Register "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v("mdi-checkbox-marked-circle")])],1)],1),_c('v-col',{staticClass:"pa-3 d-flex flex-column",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-card',{staticClass:"elevation-5 flex d-flex flex-column"},[_c('v-card-title',[_vm._v("General")]),_c('v-card-text',[_c('v-spacer'),_c('v-divider'),(this.purpose == 'new')?_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.productMarks,"item-text":"Mark","return-object":true,"label":"Choisir la marque","hide-details":"","required":"","hide-selected":""},on:{"input":_vm.onProductMarksChange}})],1):_vm._e(),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date de sortie","hint":"DD/MM/YYYY format","persistent-hint":"","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.releaseDate),callback:function ($$v) {_vm.releaseDate=$$v},expression:"releaseDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuCalendar),callback:function ($$v) {_vm.menuCalendar=$$v},expression:"menuCalendar"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menuCalendar = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-text-field',{attrs:{"label":"Nom","required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-textarea',{attrs:{"name":"description","label":"Description","hint":"Hint text"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},_vm._l((_vm.configs),function(config){return _c('v-col',{key:config.id},[_c('ShowConfiguration',{attrs:{"showBloc":_vm.showBloc,"objectMark":_vm.mark,"objectType":_vm.type,"id":config.id,"value":config},on:{"input":function (newConfiguration) {
                  config = newConfiguration;
                }}})],1)}),1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-card',{staticClass:"elevation-5 flex d-flex flex-column"},[_c('v-card-title',[_vm._v("Configurations")]),_c('v-spacer'),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"sm":"1"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.addConfiguration}},[_vm._v(" Add a config "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v("mdi-plus")])],1)],1)],1),_vm._l((_vm.configs),function(config){return _c('v-col',{key:config.id},[_c('Configuration',{attrs:{"show":_vm.show,"objectMark":_vm.mark,"objectType":_vm.type,"id":config.id,"value":config},on:{"remove":_vm.removeConfig,"input":function (newConfiguration) {
                      config = newConfiguration;
                    }}})],1)})],2)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }